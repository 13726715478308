<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        {{ titleZh  }}
      </div>
      <div class="topic_desc_en">
        {{ titleEn  }}
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc">
        {{thematicDesc}}
      </div>
      <div class="section_content">
        <div class="section_title section_title_feature">服务特色</div>
        <div class="section_main">
          <div class="modeule_cards">
            <div class="modeule_cards_line" v-for="(lItem,lIdx) in fwts" :key="lIdx">
              <div class="modeule_cards_el" v-for="(eItem,eIdx) in lItem" :key="eIdx">
                <div class="modeule_cards_label">
                  {{eItem.name}}
                </div>
                <div class="modeule_cards_desc">
                  {{eItem.value}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section_content">
        <div class="section_title section_title_range">服务范围</div>
        <div class="section_card">
          <div class="range_topic">
            {{rangesTitle}}
          </div>
          <div class="range_items_content">
            <div class="range_items" v-for="(item ,idx) in ranges" :key="idx">
              <div class="range_icon"></div>
              <div class="range_value">
                {{item}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      "thematicDesc":"",
      "fwts":[],
      "rangesTitle":"",
      "ranges":[],
      "titleZh":"",
      "titleEn":"",
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      let lang = this.$i18n.locale;
      return this.infoData[lang] || "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeService&secondaryTitle=screeningAndDruggabilityEvaluation`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.thematicDesc = configData.thematicDesc;
          _this.fwts = configData.fwts;
          _this.rangesTitle = configData.rangesTitle;
          _this.ranges = configData.ranges;
          _this.titleZh = configData.titleZh;
          _this.titleEn = configData.titleEn;
        }else{
          _this.$notify({
            title: '失败',
            message: "早期药物筛选及成药性评价数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 66px;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-bottom: 43px;
    .module_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 32px;
      margin-bottom: 90px;
    }
    .section_content{
      .section_title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #000000;
        height: 35px;
        line-height: 35px;
        padding-left: 57px;
        box-sizing: border-box;
        &.section_title_feature{
          background: url('./images/titleBg@feature.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
        &.section_title_range{
          background: url('./images/titleBg@range.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
      }
      .section_main{
        margin-bottom: 60px;
        margin-top: 40px;
        .modeule_cards{
          .modeule_cards_line{
            display: flex;
            margin-bottom: 30px;
            &:last-child{
              margin-bottom: 0;
            }
            .modeule_cards_el{
              flex: 1;
              overflow: hidden;
              margin-right: 36px;
              background: #F6F7F9;
              padding: 10px 32px 40px 32px;
              box-sizing: border-box;
              &:last-child{
                margin-right: 0;
              }
              .modeule_cards_label{
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 24px;
                color: #0086D1;
                line-height: 53px;
              }
              .modeule_cards_desc{
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                line-height: 40px;
              }
            }
          }
        }
      }
      .section_card{
        width: 100%;
        background: #F6F7F9;
        padding: 10px 32px 18px 32px;
        box-sizing: border-box;
        margin-bottom: 59px;
        margin-top: 36px;
        .range_topic{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 24px;
          color: #0086D1;
          line-height: 53px;
          margin-bottom: 10px;
        }
        .range_items_content{
          display: flex;
          flex-wrap: wrap;
          .range_items{
            display: flex;
            align-items: center;
            height: 40px;
            margin-right: 100px;
            &:last-child{
              margin-right: 0;
            }
            .range_icon{
              width: 15px;
              height: 15px;
              background: url("./images/icon@checked.png") 0 0 no-repeat;
              background-size: 100% auto;
              background-position: center left;
              margin: 10px 7px 10px 0;
            }
            .range_value{
              flex: 1;
              overflow: hidden;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
            }
          }
        }
      }
      .section_main{
        margin-top: 26px;
        .anli_line{
          margin-bottom: 46px;
          display: flex;
          align-items: flex-end;
          &:last-child{
            margin-bottom: 0;
          }
          .anli_card{
            flex: 1;
            overflow: hidden;
            margin-right: 90px;
            img{
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>